//		Réglages globaux

@import '../base/variables';
@import '../tools/mixins';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

// Add font

@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-Light', 100, normal, ttf);
@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-LightItalic', 100, italic, ttf);
@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-Italic', 300, italic, ttf);
@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-Regular', 300, normal, ttf);
@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-SemiBold', 500, normal, ttf);
@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-SemiBoldItalic', 500, italic, ttf);
@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-Bold', 700, normal, ttf);
@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-BoldItalic', 700, italic, ttf);
@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-ExtraBold', 900, normal, ttf);
@include font-face('Open Sans', $font-dir + '/OpenSans/OpenSans-ExtraBoldItalic', 900, italic, ttf);

*,
*::after,
*::before {
  outline: none;
  position: relative;
  -webkit-tap-highlight-color: none;
}

// Better font rendering for mac
.is-mac,
.is-mac input,
.is-mac button,
.is-mac textarea {
  @include antialiased;
}

body {
  background-color: $background-black;
  background-image: url($img-dir + 'background.jpg');
  background-size: cover;
  color: $white-gold;
  font-family: 'Open Sans';
  font-size: $font-size;
  font-weight: 300;
  line-height: $line-height;
  overflow-x: hidden;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}

p,
ul,
ol,
blockquote {
  margin-bottom: $line-height * 1em;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;
}

h1 {
  font-size: 3.157em;
  margin-top: 0;
}

h2 {
  font-size: 2.369em;
}

h3 {
  font-size: 1.777em;
}

h4 {
  font-size: 1.333em;
}

small {
  font-size: .75em;
}

sup {
  font-size: .75em;
  vertical-align: super;
}

a {
  color: inherit;
  @include transition;
}

p {
  margin-bottom: 0;
}


ul,
ol {
  margin-left: 1em;
  padding-left: 1em;
}

b,
strong {
  font-weight: bold;
}

.circle {
  position: absolute;
  width: 1000px;
  max-width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: .2;
}

#more-infos-section {
  padding-top: 105px;
  padding-bottom: 105px;
  border-top: 1px solid rgba(230,213,176,.5);
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .lights {
    position: absolute;
    right: 0;
    bottom: 0;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .lights:nth-child(2) {
    position: absolute;
    left: 0px;
    bottom: -250px;
  }

  .title-section {
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
    color: $gold;
    text-transform: uppercase;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .introduction {
    margin-bottom: 60px;
    color: $white-gold;
    font-style: italic;
    font-size: 16px;
    line-height: 30px;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 22px;
    }

    a {
      color: $gold;
      font-weight: bold;
    }
  }

  #map {
    width: 100%;
    height: 470px;

    @include media-breakpoint-down(md) {
      height: 350px;
    }
  }

  .access-title {
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: $gold;

    @include media-breakpoint-down(md) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .access {
    margin-bottom: 35px;

    .title {
      margin-bottom: 3px;
      color: $white;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .picto {
      display: inline-block;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      vertical-align: top;
    }

    .description {
      display: inline-block;
      width: calc(100% - 40px);
      color: $white-gold;
      font-size: 16px;
      line-height: 24px;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.gm-style {
  .gm-style-iw {
    background-color: $black !important;
    color: $white !important;
    top: 11px !important;
    left: 0 !important;
    right: 0 !important;
    width: auto !important;
    font-size: 16px !important;
    padding: 5px 0 !important;
    text-align: center !important;
    display: block !important;

    button {
      display: none;
    }

    @include media-breakpoint-down(md) {
      font-size: 14px !important;
    }
  }

  > div > div:last-of-type > div > div:last-of-type > div > div:not(.gm-style-iw) > div:last-of-type {
    &:last-of-type {
      background: $black !important;
    }
  }

  div div div div div div div {
    div {
      div {
        background-color: $black !important;
      }
    }
  }
}

form {
  .form-control {
    background-color: $black !important;
    border-color: #898989 !important;
    border-radius: 0 !important;
    border-width: 2px !important;
    color: $white !important;
    padding: 14px 12px !important;
    height: auto !important;
    font-size: 14px !important;
    box-shadow: none !important;

    &:focus {
      border-color: $white !important;
    }
  }

  select {
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    background-image: url($img-dir + 'background_select.png') !important;
    background-position: right !important;
    background-repeat: no-repeat;
    background-size: 53px 53px;

    option[disabled] {
      font-style: italic;
      color: #898989;
    }
  }

  .form-control {
    &::placeholder {
      font-style: italic;
      color: #898989;
    }
    &::-moz-placeholder {
      font-style: italic;
      color: #898989;
    }
  }

  .simple-content {
    position: relative;
    padding-left: 25px;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #969696 !important;

    a {
      text-decoration: underline;
      color: #969696;
    }
  }

  input[type="checkbox"] {
    display: none;

    &+ .checkbox-inline {
      position: relative;
      padding-left: 25px;
      font-size: 12px !important;
      line-height: 18px !important;
      color: #969696 !important;

      &:before {
        display: block;
        position: absolute;
        top: 5px;
        left: 0;
        content: " ";
        width: 13px;
        height: 13px;
        border: 2px solid #969696;
        line-height: 10px;
      }

      a {
        text-decoration: underline;
        color: #969696;
      }
    }
  }

  input[type="checkbox"]:checked + .checkbox-inline:before {
    content: "✓";
  }

  label {
    font-size: 16px !important;
    line-height: 30px !important;
    font-weight: normal !important;
    color: $white !important;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 22px;
    }

    .field_required {
      display: none;
    }
  }

  .form-group {
    margin-bottom: 25px !important;
  }

  input[type="submit"] {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.owl-dots {
  text-align: center;
  line-height: 50px;

  .owl-dot {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin: 0 5px;
    border: 1px solid #818181;
    vertical-align: middle;

    &.active {
      width: 12px;
      height: 12px;
      background-color: $gold;
      border-color: $gold;
    }
  }
}

.cookiebanner {
  padding: 17px 10px !important;
  background: #736357 !important;
  color: #fff !important;
  font-family: 'Open Sans' !important;
  font-size: 13px !important;
  font-weight: bold !important;
  line-height: 40px !important;
  z-index: 1000 !important;

  @include media-breakpoint-down(md) {
    line-height: 22px !important;
  }

  .cookiebanner-close {
    margin-left: 25px;
    color: #736357 !important;
    background: $white !important;
    height: 40px !important;
    width: 40px !important;
    float: none !important;
    text-align: center !important;
    padding: 0 !important;
    display: inline-block;

    @include media-breakpoint-down(md) {
      line-height: 40px !important;
    }
  }
}

#mailchimp-top-bar {
  bottom: -75px !important;
  min-height: 74px !important;
  z-index: 10 !important;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  form {
    @include media-breakpoint-down(md) {
      text-align: left !important;
    }
  }

  .mctb-close {
    top: -55px !important;
    margin-right: 0 !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px !important;

    @include media-breakpoint-down(md) {
      position: absolute;
      right: 0 !important;
      top: -75px !important;
    }
  }

  .mctb-bar {
    padding: 17px 10px !important;
    color: #fff !important;
    font-family: 'Open Sans' !important;
    font-size: 13px !important;
    font-weight: bold !important;
    line-height: 40px !important;
    background: rgba(175, 152, 100, .9);
    text-transform: uppercase;
    top: -75px !important;

    .mctb-email {
      padding: 10px !important;
      font-size: 14px !important;
      border: none !important;
      line-height: 30px;

      @include media-breakpoint-down(md) {
        width: calc(100% - 65px);
      }
    }

    .mctb-button {
      height: 40px;
      margin-left: -2px;
      background: $gold !important;
      border: none;
      vertical-align: bottom;

      img {
        width: 30px;
        padding: 0;
        line-height: 30px;
        text-align: center;
      }
    }

    .mctb-response {
      margin-top: -10px;

      .mctb-response-label {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}

.fancybox-navigation {
  top: 0px;
  height: 100%;
}

.cfdatepicker table tr td.active.active {
  color: $black !important;
  background-color: $gold !important;
  border-color: $gold !important;
}