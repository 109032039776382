@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Footer
\*------------------------------------*/

footer#footer {
  overflow: hidden;
  position: relative;

  .circle {
    top: 0;
  }

  .top-part {
    padding-bottom: 70px;
    padding-top: 70px;
    border-top: 1px solid rgba(230, 213, 176, .5);

    .logo-retro {
      display: block;
      height: 170px;
      margin-bottom: 35px;
      text-align: center;

      img {
        height: 100%;
      }
    }

    .section-title {
      margin-bottom: 20px;
      color: $light-gold;
      font-size: 17px;
      font-weight: bold;

      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }
    }

    .social-networks {
      a {
        display: inline-block;
        margin-right: 25px;
        text-decoration: none;
        opacity: .75;

        img {
          width: 20px;
        }

        &:hover,
        &:focus,
        &:active {
          opacity: 1;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .phone-numbers {
      a {
        color: $light-gold;

        &:hover,
        &:focus,
        &:active {
          color: $white-gold;
        }
      }
    }
  }

  .bottom-part {
    padding-bottom: 30px;
    padding-top: 20px;
    border-top: 1px solid rgba(230, 213, 176, .5);
    text-align: center;
    font-size: 14px;
    color: #a99e85;

    @include media-breakpoint-down(sm) {
      font-size: 13px;
    }

    .links {
      a {
        color: #a99e85;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active,
        &.active {
          color: #fbe7bd;
        }
      }
    }

    .logo-tiz {
      display: inline-block;
      width: 50px;
      margin-top: 15px;

      img {
        width: 100%;
      }
    }

    .spot {
      position: absolute;
      height: 180px;
      bottom: 0;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      &:first-of-type {
        left: 0;
      }

      &:nth-of-type(2) {
        left: 15%;
      }

      &:nth-of-type(3) {
        left: 30%;
      }

      &:nth-of-type(4) {
        right: 30%;
      }

      &:nth-of-type(5) {
        right: 15%;
      }

      &:last-of-type {
        right: 0;
      }
    }
  }

  .mc4wp-form {
    input[type="email"] {
      padding: 10px;
      font-size: 14px;
      border: none;
      width: calc(100% - 81px);
      float: left;
    }

    button[type="submit"] {
      width: 41px;
      height: 41px;
      padding: 0;
      background: $gold;
      line-height: 41px;
      text-align: center;
      border: none;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}