@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
						Main menu
\*------------------------------------*/

.navbar-nav {
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;

  li {
    display: inline-block;
    list-style: none;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

    a {
      color: #a99e85;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active,
      &.active {
        color: #fbe7bd;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.left {
      li {
        margin-right: 30px;
      }
    }

    &.right {
      justify-content: flex-end;

      li {
        margin-left: 30px;
      }
    }
  }
}