@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Buttons
\*------------------------------------*/

.button {
  display: inline-block;
  padding: 12px 35px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  @include transition;

  @include media-breakpoint-down(lg) {
    padding: 15px 25px;
  }

  @include media-breakpoint-down(md) {
    padding: 12px 20px;
    font-size: 14px;
  }

  @include media-breakpoint-up(xl) {
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
}


.btn-black {
  background: $black;
  color: $white;
  border: 1px solid $light-gold;

  &:hover, &:focus, &:active, &.active {
    color: $white;
    background: $gold;
    text-decoration: none;
  }
}

.btn-gold {
  background: $gold;
  color: $white;
  border: 1px solid $gold;

  &:hover, &:focus, &:active, &.active {
    background: $light-gold;
    color: $white;
    text-decoration: none;
  }
}