@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';


// Header


#header-top {
  padding-bottom: 70px;
  padding-top: 25px;
  position: relative;

  .circle {
    top: -300px;

    @include media-breakpoint-down(sm) {
      top: -150px;
    }
  }

  .spot {
    position: absolute;
    height: 180px;
    top: 0;
    transform: rotate(180deg);

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:first-of-type {
      left: 0;
    }

    &:nth-of-type(2) {
      left: 15%;
    }

    &:nth-of-type(3) {
      left: 30%;
    }

    &:nth-of-type(4) {
      right: 30%;
    }

    &:nth-of-type(5) {
      right: 15%;
    }

    &:last-of-type {
      right: 0;
    }
  }

  .top-part {
    position: absolute;
    top: 20px;
    width: calc(100% - 30px);
    z-index: 1;

    @include media-breakpoint-down(md) {
      top: -15px;
    }

    a {
      color: #a99e85;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active,
      &.active {
        color: #fbe7bd;
      }
    }

    .lang-switcher {
      float: left;
      padding-left: 0;
      margin-left: 0;
      list-style: none;

      li {
        display: inline-block;
        margin-right: 21px;

        @include media-breakpoint-down(md) {
          margin-right: 11px;
        }

        a {
            text-transform: uppercase;
        }
      }
    }

    .social-networks {
      float: right;

      a {
        display: inline-block;
        margin-left: 25px;
        text-decoration: none;
        opacity: .75;

        @include media-breakpoint-down(md) {
          margin-left: 15px;
        }

        img {
          width: 20px;
        }

        &:hover,
        &:focus,
        &:active {
          opacity: 1;
        }
      }
    }
  }

  .navbar {
    height: 256px;
    padding: 0;

    @include media-breakpoint-down(lg) {
      height: 150px;
    }

    .logo-retro {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      text-align: center;

      @include media-breakpoint-down(md) {
        height: 120px;
      }

      a {
        display: inline-block;
        height: 100%;

        img {
          height: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .lights-logo {
      position: absolute;
      width: 710px;
      max-width: 100%;
      left: 0;
      right: 0;
      top: 40px;
      margin-left: auto;
      margin-right: auto;
      opacity: .5;

      @include media-breakpoint-down(md) {
        top: -120px;
      }

      @include media-breakpoint-down(sm) {
        top: 0;
      }
    }
  }

  .navbar-toggler {
    border: none;
    outline: 0;

    .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      transition: all 0.2s;
      background-color: $gold;
      background-color: #c1a76e;

      &.top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
      &.middle-bar {
        opacity: 0;
      }
      &.bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
    }

    .icon-bar+.icon-bar {
      margin-top: 4px;
    }

    &.collapsed {
      .top-bar {
        transform: rotate(0);
      }
      .middle-bar {
        opacity: 1;
      }
      .bottom-bar {
        transform: rotate(0);
      }
    }
  }

  .logo-toggled {
    display: none;
  }

  .circle-toggled {
    display: none;
  }

  @include media-breakpoint-down(md) {
    .navbar-toggler {
      position: fixed;
      top: 45px;
      padding: 0;
      z-index: 1000;
    }

    .navbar-collapse {
      position: fixed;
      top: 0px;
      width: 100%;
      height: 100%;
      background: #0f0d0b;
      left: 0;
      z-index: 10;
      text-align: center;

      .logo-toggled {
        display: block;
        width: 120px;
        margin-bottom: 40px;
        margin-top: 40px;
      }

      .circle-toggled {
        display: block;
        position: absolute;
        width: 600px;
        top: -250px;
        left: -300px;
        opacity: .4;
      }

      .navbar-nav li {
        padding-top: 5px;
        padding-bottom: 5px;

        a {
          font-size: 16px;

          @include media-breakpoint-down(md) {
            font-size: 20px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
